<template>
<div class="option-range">
    <div class="list-radio">
        <div class="form-radio-box mr-5">
            <input
                class="form-radio-input implement"
                type="radio"
                value="organic"
                name="youtube_report_radio"
                data-total-report="action"
                @click="clickRadio()"
                checked
            />
            <span class="btn-radio"></span>
            <label class="label-radio">オーガニック</label>
        </div>
        <div class="form-radio-box mr-5">
            <input
                class="form-radio-input not-implement"
                type="radio"
                value="ad_instream"
                name="youtube_report_radio"
                data-total-report="action"
                @click="clickRadio()"
            />
            <span class="btn-radio"></span>
            <label class="label-radio">広告</label>
        </div>
        </div>
</div>
</template>
<script>
const jQuery = require("jquery");
export default {
    props: {
        youtubeAudienceRating: Object
    },
    data() {
        return {
        }
    },
    // mounted() {
    //     this.clickRadio();
    // },
    methods: {
        clickRadio() {
            let youtubeReportType = jQuery("input:radio[name=youtube_report_radio]:checked").val();
            this.$emit("click-youtube-radio", youtubeReportType);
        }
    },
};
</script>
<style lang="scss">
@import '@/styles/variable';
    .list-radio {
        display: flex;
        margin-top: 10px;
    }
    .form-radio-box {
        position: relative;
        .form-radio-input {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        .btn-radio {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            padding: 3px;
            border: 1px solid $middle-black;
            border-radius: 8px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 3px;
                top: 3px;
                bottom: 3px;
                right: 3px;
                border-radius: 8px;
                background: $light-black;
            }
        }
        .form-radio-input:checked + .btn-radio:after {
            background-color: $green;
        }
        .form-radio-input:checked + .btn-radio {
            border: 1px solid $green;
        }
        .label-radio {
            margin-left: 8px;
            @include font(m);
        }
    }
</style>